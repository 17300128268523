.footer {
    background: rgb(0, 0, 0);
}
.footer p {
    padding: 2em 0;
    text-align: center;
    font-size: 14px;
    color: #fff;
    letter-spacing: 2px;
    font-weight: 100;
}