.btn-orange {
  background-color: #ED6737;
}

.nav-pills .nav-link.active {
  color: white;
  background-color: orangered;
  font-weight: bold;
}
.nav-pills a {
  color: black;
}
.nav-pills a:hover {
  color: orangered;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .mobile {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  /* For computers and tablet */
  .bigScreen {
    display: none;
  }
}